var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "loading-mask",
      style: { backgroundColor: _vm.backgroundColor },
    },
    [
      _c("vue-loading", {
        attrs: {
          type: "spiningDubbles",
          color: "#efad4c",
          size: { width: "100px", height: "100px" },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }